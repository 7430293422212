(function () {
  const cardSize = 20;
  const cardItems = [];
  const cardPaired = [];
  const cardActive = [];
  let timerInstance;
  let movesCounter = 0;
  let timerCounter = 0;
  let blocked = false;
  let timeout;

  document.addEventListener("DOMContentLoaded", () => {
    if (document.querySelector("#memoryGame")) {
      init();

      document.querySelector("#memoryGameRun").addEventListener("click", () => {
        resetGame();
      });
    }
  });

  function init() {
    clearInterval(timerInstance);
    timerInstance = setInterval(() => {
      timerCounter++;
      updateResults();
    }, 1000);
    initHtml();
    initEvents();
    updateResults();
  }

  function initHtml() {
    const memory = document.querySelector("#memoryGame");
    const memoryGame = memory.querySelector(".memory__game");

    const random = Math.floor(Math.random() * 10);

    for (var i = 0; i < cardSize; i++) {
      const card = document.createElement("div");

      const cardKey = random + randomizeCards();
      card.classList.add("memory__card");
      card.setAttribute("data-key", cardKey);

      const cardBack = document.createElement("div");
      cardBack.classList.add("memory__card-side");
      cardBack.classList.add("memory__card-side--back");

      const cardFront = document.createElement("div");
      cardFront.classList.add("memory__card-side");
      cardFront.classList.add("memory__card-side--front");

      card.append(cardFront);
      card.append(cardBack);

      memoryGame.append(card);
    }
  }

  function randomizeCards() {
    const randomNumber = Math.floor(Math.random() * (cardSize / 2));

    if (cardKeyExist(randomNumber)) {
      return randomizeCards();
    } else {
      cardItems.push(randomNumber);
    }

    return randomNumber;
  }

  function cardKeyExist(key) {
    return cardItems.filter((item) => item === key).length >= 2;
  }

  function initEvents() {
    const memory = document.querySelector("#memoryGame");
    const memoryCards = memory.querySelectorAll(".memory__card");

    memoryCards.forEach((card) => {
      card.addEventListener("click", mainLoop);
    });
  }

  function mainLoop(event) {
    if (!blocked) {
      const key = event.target.getAttribute("data-key");
      event.target.classList.add("memory__card--active");
      cardActive.push(key);

      if (cardActive.length >= 2) {
        blocked = true;
        movesCounter++;
        updateResults();
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          if (cardActive[0] === cardActive[1]) {
            addPair(cardActive[0]);
            disableCard(cardActive[0]);
          }

          if (isGameWinned()) {
            winGame();
          } else {
            resetCards();
          }
        }, 1000);
      }
    }
  }

  function resetCards() {
    const memory = document.querySelector("#memoryGame");
    const memoryCards = memory.querySelectorAll(".memory__card");
    memoryCards.forEach((card) => {
      card.classList.remove("memory__card--active");
      cardActive.length = 0;
    });
    blocked = false;
  }

  function isGameWinned() {
    return cardPaired.length === cardSize / 2;
  }

  function addPair(number) {
    cardPaired.push(number);
  }

  function disableCard(cardKey) {
    const memory = document.querySelector("#memoryGame");
    const memoryCards = memory.querySelectorAll(
      `.memory__card[data-key="${cardKey}"]`
    );

    memoryCards.forEach((card) => {
      card.classList.add("memory__card--disabled");
    });
  }

  function resetGame() {
    cardItems.length = 0;
    cardPaired.length = 0;
    cardActive.length = 0;
    movesCounter = 0;
    timerCounter = 0;
    blocked = false;
    clearTimeout(timeout);

    const memory = document.querySelector("#memoryGame");
    const memoryGame = memory.querySelector(".memory__game");
    const memoryCards = memory.querySelectorAll(".memory__card--active");

    memoryCards.forEach((card) => {
      card.removeEventListener("click", mainLoop);
    });

    memoryGame.innerHTML = "";

    document.querySelector("#memoryGame").classList.remove("win");
    document.querySelector("#memoryGameWin").classList.remove("win");

    init();
  }

  function winGame() {
    clearInterval(timerInstance);
    document.querySelector("#memoryGame").classList.add("win");
    document.querySelector("#memoryGameWin").classList.add("win");
  }

  function updateResults() {
    const hours = Math.floor(timerCounter / 3600);
    const minutes = Math.floor((timerCounter / 60) % 60);
    const seconds = Math.floor(timerCounter % 60);

    let formatTimer = `${formatTime(minutes)}:${formatTime(seconds)}`;

    if (hours > 0) {
      formatTimer = `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(
        seconds
      )}`;
    }

    document.querySelector("#movesCounter").innerHTML = movesCounter;
    document.querySelector("#timeCounter").innerHTML = formatTimer;
    document.querySelector("#movesCounterWin").innerHTML = movesCounter;
    document.querySelector("#timerCounterWin").innerHTML = formatTimer;
  }

  function formatTime(number) {
    return number < 10 ? `0${number}` : number;
  }
})();
