(function () {
  let questionsData;
  let questionsResults = [];
  let questionsPeoples = [];
  let currentQuestion = 0;
  let questionsLength = 0;

  document.addEventListener("DOMContentLoaded", () => {
    const questions = document.querySelector("#questions");
    if (questions) {
      const questionsType = questions.getAttribute("data-type");
      questionsData = JSON.parse(questions.getAttribute("data-questions"));
      questions.removeAttribute("data-questions");
      questionsPeoples = JSON.parse(questions.getAttribute("data-results"));
      questions.removeAttribute("data-results");
      questionsLength = questionsData.length;

      buildQuestion(currentQuestion);

      document
        .querySelector(".single-question__button--prev")
        .addEventListener("click", function () {
          currentQuestion--;
          if (currentQuestion < 0) {
            currentQuestion = 0;
          }
          buildQuestion(currentQuestion);
        });

      document
        .querySelector(".single-question__button--next")
        .addEventListener("click", function () {
          currentQuestion++;
          if (currentQuestion >= questionsLength - 1) {
            currentQuestion = questionsLength - 1;
          }
          buildQuestion(currentQuestion);
        });

      document
        .querySelector(".single-question__button--end")
        .addEventListener("click", function () {
          document.querySelector("#game .header").remove();
          document.querySelector("#questions").remove();

          if (questionsType === "who") {
            buildWhoSuccessScreen();
          } else {
            buildTestSuccessScreen();
          }
        });
    }
  });

  function buildQuestion(index) {
    const currentQuestion = questionsData[index];

    document.querySelector(".single-question__text-counter").innerHTML =
      index + 1 + "/" + questionsLength;
    document.querySelector(".single-question__text-content").innerHTML =
      currentQuestion.pytanie;

    let answersHtml = "";

    for (var i = 0; i < currentQuestion.odpowiedzi.length; i++) {
      const currentAnswer = currentQuestion.odpowiedzi[i];
      const userAnswer = questionsResults.find(
        (singleResult) => singleResult.questionId === index
      );
      let className = "single-question__answer";
      if (userAnswer) {
        if (userAnswer.questionId === index && userAnswer.answerId === i) {
          className = "single-question__answer single-question__answer--active";
        }
      }
      answersHtml +=
        "<div class='" +
        className +
        "' data-question-id='" +
        index +
        "' data-answer-id='" +
        i +
        "'><span>" +
        currentAnswer.odpowiedz +
        "</span></div>";
    }

    document.querySelector(".single-question__answers").innerHTML = answersHtml;
    document.querySelectorAll(".single-question__answer").forEach((answer) => {
      answer.removeEventListener("click", answerClick);
      answer.addEventListener("click", answerClick);
    });

    buttons(index);
  }

  function answerClick(event) {
    const item = event.target;
    const questionId = parseInt(item.getAttribute("data-question-id"));
    const answerId = parseInt(item.getAttribute("data-answer-id"));

    const result = questionsResults.find(
      (singleResult) => singleResult.questionId === questionId
    );

    if (result) {
      result.questionId = questionId;
      result.answerId = answerId;
    } else {
      questionsResults.push({
        questionId: questionId,
        answerId: answerId,
      });
    }

    document.querySelectorAll(".single-question__answer").forEach((answer) => {
      answer.classList.remove("single-question__answer--active");
    });

    item.classList.add("single-question__answer--active");

    buttons(currentQuestion);
  }

  function buttons(index) {
    const buttonPrev = document.querySelector(".single-question__button--prev");
    const buttonNext = document.querySelector(".single-question__button--next");
    const buttonEnd = document.querySelector(".single-question__button--end");

    if (index === 0) {
      buttonPrev.classList.add("single-question__button--disabled");
      buttonNext.classList.remove("hide");
      buttonEnd.classList.remove("show");
    } else if (index === questionsLength - 1) {
      buttonNext.classList.add("hide");
      buttonEnd.classList.add("show");
    } else {
      buttonPrev.classList.remove("single-question__button--disabled");
      buttonNext.classList.remove("hide");
      buttonEnd.classList.remove("show");
    }

    const selectedAnswer = !!document.querySelector(
      ".single-question__answer--active"
    );

    if (selectedAnswer) {
      buttonNext.classList.remove("single-question__button--disabled");
      buttonEnd.classList.remove("single-question__button--disabled");
    } else {
      buttonNext.classList.add("single-question__button--disabled");
      buttonEnd.classList.add("single-question__button--disabled");
    }
  }

  function buildTestSuccessScreen() {
    let result = 0;

    questionsResults.forEach((question) => {
      const questionId = question.questionId;
      const answerId = question.answerId;

      if (questionsData[questionId].odpowiedzi[answerId].poprawna) {
        result++;
      }
    });

    const questionsResultsNumber = document.querySelector(
      "#questionsResultsNumbers"
    );

    questionsResultsNumber.innerHTML = result + "/" + questionsData.length;

    let questionsAndAnswersHtml = ``;

    questionsResults.forEach((question, index) => {
      const questionId = question.questionId;
      const answerId = question.answerId;

      const singleQuestion = questionsData[questionId];

      let answerHtml = ``;

      for (var i = 0; i < singleQuestion.odpowiedzi.length; i++) {
        const currentAnswer = singleQuestion.odpowiedzi[i];
        answerHtml += `<div class='single-question__answer ${
          currentAnswer.poprawna ? "single-question__answer--good" : ""
        } ${i === answerId ? "single-question__answer--user" : ""}'><span>${
          currentAnswer.odpowiedz
        }</span></div>`;
      }

      questionsAndAnswersHtml += `
        <div class="single-question">
          <div class="single-question__text">
            <div class="single-question__text-counter">${index + 1}/${
        questionsResults.length
      }</div>
            <div class="single-question__text-content">${
              singleQuestion.pytanie
            }</div>
          </div>
          <div class="single-question__answers">${answerHtml}</div>
        </div>
      `;
    });

    const ratio = result / questionsData.length;

    if (ratio <= 0.3) {
      document.querySelector("#questionsResultsOption1").classList.add("show");
    } else if (ratio > 0.3 && ratio <= 0.6) {
      document.querySelector("#questionsResultsOption2").classList.add("show");
    } else {
      document.querySelector("#questionsResultsOption3").classList.add("show");
    }

    document.querySelector(
      "#questionsAnswers"
    ).innerHTML = questionsAndAnswersHtml;

    document.querySelector("#questionsResults").classList.add("show");
  }

  let peoplesResults = [];
  function buildWhoSuccessScreen() {
    questionsResults.forEach((question, index) => {
      const questionId = question.questionId;
      const answerId = question.answerId;
      const singleQuestion = questionsData[questionId];
      const singleAnswer = singleQuestion.odpowiedzi[answerId];

      peoplesResults.push({
        id: singleAnswer.postac.value,
        points: parseInt(singleAnswer.punkty),
      });
    });

    const people = getPeopleFromResult(peoplesResults);

    document.querySelector(".who-win__name").innerHTML = people.jestes;

    document
      .querySelector(".who-win__photo")
      .setAttribute("src", people.portret.url);

    document.querySelector(".who-win__description").innerHTML = people.opis;

    document.querySelector("#whoWin").classList.add("show");
  }

  function getPeopleFromResult(result) {
    const peoplesSum = [];

    result.forEach((item) => {
      const peopleIndex = peoplesSum.findIndex(
        (people) => people.id === item.id
      );
      if (peopleIndex > -1) {
        peoplesSum[peopleIndex].points += item.points;
      } else {
        peoplesSum.push(item);
      }
    });

    const winPeople = peoplesSum.sort((a, b) => {
      return b.points - a.points;
    })[0];

    return questionsPeoples.find((people) => people.id === winPeople.id);
  }
})();
